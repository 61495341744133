import { createI18n } from 'vue-i18n';

import ar from './ar.json';
import csCZ from './cs-CZ.json';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import fi from './fi.json';
import fil from './fil.json';
import fr from './fr.json';
import id from './id.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import la from './la.json';
import ms from './ms.json';
import pt from './pt.json';
import ruRU from './ru-RU.json';
import th from './th.json';
import vi from './vi.json';
import zh from './zh.json';

const language = localStorage.getItem('language') || 'en'; // 从 localStorage 中获取语言设置，如果没有则使用默认值 'en'

const i18n = createI18n({
  legacy: false,
  locale: language || 'en',
  fallbackLocale: 'en',
  messages: {
    ar,
    csCZ,
    de,
    en,
    es,
    fi,
    fil,
    fr,
    id,
    it,
    ja,
    ko,
    la,
    ms,
    pt,
    ruRU,
    th,
    vi,
    zh
  },
});

export default i18n;